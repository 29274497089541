import * as H from "history";
import * as r from "ramda";
import React from "react";
import { 
  ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, createHttpLink, from
} from "@apollo/client";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { useHistory, useLocation } from "react-router-dom";
import { isValidNonNilGuid } from "./util";
import { LocationState } from "./containers/Login/types";
import { getCSRFTokenValue, getPimsTypeValue, getAccessToken, getContextKey } from "./cookieHelper";
import { pimsRxBasePath } from "./config/frontend";
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import {
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js';

export const retrieveContextKey = async (accessToken: string, claimCheckKey: string | null) => {
  let contextKey = sessionStorage.getItem("ContextKey");
  if (!contextKey) {
    try {
      contextKey = await getContextKey(accessToken, claimCheckKey);
      sessionStorage.setItem("ContextKey", contextKey);
    } catch (e) {
      console.error(`getContextKey for claimCheck: ${claimCheckKey} failed with err`, e);
    }
  }
  return contextKey;
};

const httpLink = createHttpLink({
  uri: `${pimsRxBasePath}/graphql`,
  credentials: "include",
});

const getUserAuthLink = (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>,
  claimCheckKey: string | null,
  history: any,
  location: H.Location<LocationState>
) =>
   setContext(async () => {
    const pimsType = getPimsTypeValue();
    const maybeCsrfToken = getCSRFTokenValue();
    let accessToken =  getAccessToken() || "" //Avimark

    if (!accessToken) {
      try {
        accessToken = await getAccessTokenSilently(); //Pulse
      } catch (e) {
        console.error(`getAccessTokenSiently for claimCheck: ${claimCheckKey} failed with err`, e);
        history.push({ pathname: "/user-not-set-up", state: { from: { location } } });
      }
    } 
  
    const contextKey = await retrieveContextKey(accessToken, claimCheckKey);
    if (!contextKey) {
      history.push({ pathname: "/user-not-set-up", state: { from: { location } } });
    }
    
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ContextKey: contextKey,
        "CLAIM-CHECK-KEY": claimCheckKey,
        "PIMS-TYPE": pimsType,
        "X-XSRF-TOKEN": maybeCsrfToken,
      },
    };
  }
);

const getLogoutLink = (history: any, location: H.Location<LocationState>) =>
  onError(({ networkError }: ErrorResponse) => {
    const pimsType = new URLSearchParams(location.search).get("pt");
    const isPulseIntegration = pimsType?.includes("evet");
    if (networkError && "statusCode" in networkError &&
        (networkError.statusCode === 401 || networkError.statusCode === 403)) {
      if(isPulseIntegration)  {
        history.push({ pathname: "/user-not-set-up", state: { from: { location } } });
      } else {
        history.push({ pathname: "/login", state: { from: { location } } });
      }
    }
  });

export const getClient = (userAuthLink: ApolloLink, logoutLink: ApolloLink) =>
  new ApolloClient({
    link: from([logoutLink, userAuthLink, httpLink]),
    cache: new InMemoryCache(),
  });

export const WrappedApolloProvider = ({ children }: any) => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { getAccessTokenSilently} = useAuth0();
  const fromLocation = r.pathOr(location.pathname, ["state", "from", "pathname"], location);
  const fromLocationParts = r.split("/", fromLocation);

  const indexOfValidGuid = r.indexOf(true, r.map(isValidNonNilGuid, fromLocationParts));
  const maybeClaimCheckKey = fromLocationParts[indexOfValidGuid] || null;
  const userAuthLink = getUserAuthLink(getAccessTokenSilently, maybeClaimCheckKey, history, location);
  const logoutLink = getLogoutLink(history, location);

  const client = getClient(userAuthLink, logoutLink)

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default WrappedApolloProvider;