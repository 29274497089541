import * as r from "ramda";
import { pimsRxBasePath } from "../src/config/frontend";

const CSRF_TOKEN_NAME = "XSRF-TOKEN";
const PIMS_TYPE_NAME = "PIMS-TYPE";
const ACCESS_TOEKN = "accessToken";

const getRawCookies = () => r.split("; ", document.cookie)
const parseCookies = (): Map<string,string|null> => {
  const cookies = new Map<string, string|null>()
  r.map(v => r.split("=", v), getRawCookies())
    .forEach((v) => {
      if(v && v.length === 2) {
        cookies.set(decodeURIComponent(v[0].trim()), decodeURIComponent(v[1].trim()));
      }
    });
  return cookies;
};

export const getAccessToken = () => {
  var cookiestring=RegExp(ACCESS_TOEKN+"=[^;]+").exec(document.cookie);
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
};

// This value comes from a cookie but needs to be placed in an
// X-XSRF-TOKEN header on eache request
export const getCSRFTokenValue = () : string | null | undefined => {
  return parseCookies().get(CSRF_TOKEN_NAME);
};

export const getPimsTypeValue = () : string | null | undefined => {
  return parseCookies().get(PIMS_TYPE_NAME);
};

export const getContextKey = async (accessToken: string, claimCheckKey: string | null, ): Promise<string> => { 
  try {
    const response = await fetch(`${pimsRxBasePath}/context-key`, {
      method: "GET",
      headers: { 'Access-Token': `${accessToken}`, 'Claim-Check-Key': claimCheckKey || ""},
      cache: 'no-cache'
    });    
    return Promise.resolve(response.text());
  } catch (e) {
    return Promise.reject(e);
  }
};